import { Link ,useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword,signOut} from "../firebaseConfig"
import {auth} from "../firebaseConfig"


export default function Sidebar(){

    
    const navigate = useNavigate();
    const logout = (e) => {
        e.preventDefault();
          signOut(auth)
        .then(() => {
          console.log(auth)
          navigate("/") 
        })
        .catch((error) => {
          console.log(error)
        });
    }
    return (
        <div>
                            <div className="sidebar">
                                  
                                <div className="sidebar--item">
                                    <Link to='/' className="link__sidebar"><p><i className="fa-brands fa-dashcube"></i> Tableau de bord</p></Link>
                                </div>
                                <div className="sidebar--item">
                                    <Link to='/student' className="link__sidebar"><p><i className="fa-solid fa-users"></i> Etudiants</p></Link>
                                </div>
                                <div className="sidebar--item">
                                    <Link to='/bulletins' className="link__sidebar"><p><i className="fa-solid fa-note-sticky"></i> Bulletins</p></Link>
                                </div>
                                <div className="sidebar--item">
                                    <Link to='/notes' className="link__sidebar"><p><i className="fa-solid fa-note-sticky"></i> Notes</p></Link>
                                </div>
                                <div className="sidebar--item">
                                    <Link to='/new' className="link__sidebar"><p><i className="fa-solid fa-pen"></i> Inscription</p></Link>
                                </div>
                                <div className="sidebar--item">
                                    <Link to='/mon-profil' className="link__sidebar"> <p> <i className="fa-solid fa-user"></i> Mon Profil</p></Link>
                                </div>
                            </div>
                            <div className="container__info__assisst">
                            <p><i class="fa-solid fa-phone"></i> Service assistance</p>
                                <p>+225 07 77 88 00 82</p>
                                <p>doumbia77fode@gmail.com</p>
                            </div>
                            <div>
                            <button className="btn__log" onClick={logout}> <i class="fa-solid fa-right-from-bracket"></i> Deconnexion</button>
                            </div>

        </div>
    )
}