const initialState = {
    persons: [],
    person:[],
    rental:[],
    user:[],
    selectedProfil : [],
    rentHomes: [],
    rentHome:[],
    newPerson: []
  
}

export function peopleReducer(state = initialState, action) {
    switch (action.type) {
        case "GET-PERSON": {
            return {
                ...state,person :action.payload
            } 
        }
        case "GET-ALL-PERSONS": {
            return {
                ...state,persons :action.payload
            } 
        }
        case "GET-USER": {
            return action.payload
        }
        case "ADD-PERSON": {
            return action.payload
        }
        case "ADD-PAYMENT": {
            return action.payload
        }
        case "GET-RENT": {
            return {
                ...state,rental :action.payload
            } 
        }
        case "GET-HOMES": {
            return {
                ...state,rentHomes :action.payload
            } 
            }
        case "GET-HOME": {
            return {
                ...state,rentHome :action.payload
            } 
        }
        
        // case "SELECT-USER":{
        //     let newState = [...state.peoples] 
        //     let people = newState.find(people => people.id == action.payload);
        //     return {...state, selectedProfil : people};
        // }

        // case "SET-USER":{
        //     let newState = [...state.peoples] 
        //     let index = newState.findIndex(people => people.id == action.payload.id);
        //     newState[index] = action.payload;
        //     return {...state, people : newState};
        // }
        default: {
            return state
        }
    }
  
}