import { useState } from "react";
import { useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";
import Navbar from "./Navbar";
import Sidebar from "../Components/Sidebar";


export default function NewStudent({setNewPeople,newPeople}){
    const dispatch = useDispatch()
    const navigate = useNavigate();
    var id = useParams().id;
    const persons = useSelector(state=>state.peopleReducer.persons);
    const UserDisplay = () =>{
        setNewPeople(false)
    }

    const [name,setFullName]=useState("");
    const [prenom,setPrenom]=useState("");
    const [birth,setDate]=useState("");
    const [lieu,setLieu]=useState("");
    const [nationality,setNationality]=useState("");
    const [situation,setSituation]=useState("");
    const [sexe,setSexe]=useState("");
    const [tel,setTel]=useState("");
    const [profession,setProfession]=useState("");
    const [address,setAddresse]=useState("");
    const [email,setEmail]=useState("");
    const [house,setHouse]=useState("");
    const [piece,setPiece]=useState("");
    const [current_status,setCurrentStatus]=useState("");
    const [city,setCity]=useState("");
    const [date_entrance,setDateEntrance]=useState("");
    const [date_emission,setEmission]=useState("");
       const [date_expiration,setExpiration]=useState("")

    const handleFull = (event) =>{
        setFullName(event.target.value)
    }
    const handlePrenom = (event) =>{
        setPrenom(event.target.value)
    }
    const handleLieu = (event) =>{
        setLieu(event.target.value)
    }
    const handleDate = (event) =>{
        setDate(event.target.value)
    }
    const handleEntrance = (event) =>{
        setDateEntrance(event.target.value)
    }
    const handleNationality = (event) =>{
        setNationality(event.target.value)
    }
    const handleSituation = (event) =>{
        setSituation(event.target.value)
    }
    const handleSexe = (event) =>{
        setSexe(event.target.value)
    }
    const handlePiece = (event) =>{
        setPiece(event.target.value)
    }
    const handleTel = (event) =>{
        setTel(event.target.value)
    }
    const handleProfession = (event) =>{
        setProfession(event.target.value)
    }
    const handleAddresse = (event) =>{
        setAddresse(event.target.value)
    }
    const handleEmail = (event) =>{
        setEmail(event.target.value)
    }
    const handleHouse = (event) =>{
        setHouse(event.target.value)
    }
    const handleGuarantee = (event) =>{
        setGuarantee(event.target.value)
    }
    // const handleRents = (event) =>{
    //     setRents(event.target.value)
    // }
    const handleCurrent = (event) =>{
        setCurrentStatus(event.target.value)
    }
    const handleCity = (event) =>{
        setCity(event.target.value)
    }
    const handleExpiration = (event) =>{
        setExpiration(event.target.value)
    }
    const handleEmission = (event) =>{
        setEmission(event.target.value)
    }
    const [search,setSearch]=useState("");
    const handleSearch = (event) =>{
        setSearch(event.target.value)
    }
  
    const handleAdd = (e) =>{
        const dataForm = {
          name,prenom,birth,lieu,nationality,address,current_status,date_expiration,
          situation,sexe,tel,profession,email,piece,date_entrance,date_emission,home_id:house,
        }
        fetch('https://mayedo.onrender.com/persons',{
              method:"POST",
              headers :{'Content-Type':"application/json"},
              body: JSON.stringify(dataForm)
          }).then((res)=>res.json())
          .then((dataPerson)=> dispatch(addPerson(dataPerson))) 
            .catch(err => {console.log(err)})
            navigate('/users');
            console.log(dataForm)
      };

      
    useEffect(() => {
        fetch('https://mayedo.onrender.com/homes')
        .then((res)=>res.json())
        .then((rentHomes)=>{dispatch(getAllHomes(rentHomes))})
        .catch(e => { console.log(e)})
        }, [])

       
  
    return (
        <div>
        <div className="containers">
        <Navbar />
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Nouvelle Inscription</li>
                                </ol>
                            </nav>

                                    <div className="container__newStudent">
                                        <div className="container__newStudent__content">
                                    <div className="container__payment__header">
                                    <h3 className="gle"><i className="fa-solid fa-users"></i> Nouvelle Inscription</h3>
                                    {/* <i className="fa-solid fa-xmark" onClick={UserDisplay}></i> */}
                                    </div>
                                    <h5 className="fess__title">Etat civil</h5>
                                      <form className="row g-3">
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Nom</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-4">
                                              <label htmlFor="validationDefault02" className="form-label">Prénom(s)</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handlePrenom} value={prenom|| ""} defaultValue="Search..."/>
                                          </div>
                                          
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Date de naissance</label>
                                              <input type="date" className="form-control" id="validationDefault02" required onChange={handleDate} value={birth} />
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Lieu</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handleLieu} value={lieu}/>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Nationalité</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handleNationality} value={nationality} />
                                          </div>
                                          
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Ville</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handleCity} value={city}/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Tel</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handleTel} value={tel}/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Profession</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handleProfession} value={profession}/>
                                          </div>
                                     
                                          <div className="col-md-4">
                                              <label htmlFor="validationDefault02" className="form-label">Email</label>
                                              <input type="email" className="form-control" id="validationDefault02" required onChange={handleEmail} value={email}/>
                                          </div>
                                          <div className="col-md-3">
                                              <label htmlFor="validationDefault02" className="form-label">Domicile</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handleAddresse} value={address}/>
                                          </div>
                                          <div className="col-md-3">
                                              <label htmlFor="validationDefault02" className="form-label">N° CNI ou PASSEPORT</label>
                                              <input type="text" className="form-control" id="validationDefault02" required onChange={handlePiece} value={piece}/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Date d'emission</label>
                                              <input type="date" className="form-control" id="validationDefault02" required onChange={handleEmission} value={date_emission} />
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Date d'expiration</label>
                                              <input type="date" className="form-control" id="validationDefault02" required onChange={handleExpiration} value={date_expiration} />
                                          </div>
                                      
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Date d'inscription</label>
                                              <input type="date" className="form-control" id="validationDefault02" required onChange={handleEntrance} value={date_entrance} />
                                          </div>
                                          <div className="col-md-4">
                                              <label htmlFor="validationDefault02" className="form-label">Nom & Prénom  du père</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">N° du père</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-4">
                                              <label htmlFor="validationDefault02" className="form-label">Nom & Prénom de la mère</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">N° de la mère</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          
                                        </form>
                                        <div className="container__fees">
                                          <div className="container__fees__xl">
                                          <h5 className="fess__title">Scolarité</h5>
                                        <form className="row g-3">
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Transfert</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Niveau</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Classe</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Serie</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Redoublant</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">Oui</option>
                                                      <option value="F">Non</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Statut (Aff/Naff)</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                          <label htmlFor="validationDefault02" className="form-label">Bourse</label>
                                                  <select className="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                      <option selected>Sélectionner</option>
                                                      <option value="M">M</option>
                                                      <option value="F">F</option>
                                                      </select>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Frais de scolarité</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Frais inscription</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">1er Versement</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Cantine</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                          <div className="col-md-2">
                                              <label htmlFor="validationDefault02" className="form-label">Transport</label>
                                              <input type="text" className="form-control" id="validationDefault02" required  onChange={handleFull} value={name|| ""} defaultValue="Search..."/>
                                          </div>
                                        </form>
                                          </div>
                                        </div>
                                    </div>
                                    <div className="btn--block--update">
                                                  <button className="btn__" onClick={UserDisplay}>Annuler</button>
                                                  <button className="btn__" onClick={handleAdd}>Enregistrer</button>
                                          </div>
                                      
                                    </div>

                            <div>
                                
                            </div>
                           </div>
                       </div> 
                   </div>
    </div>
        </div>
      
    )
}