import { useCallback, useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
// import { getAllPeople } from "../Redux/actions";
import Navbar from "./Navbar";
import React, { PureComponent } from 'react';
import { Blocks } from "react-loader-spinner";


export default function Accueil (){

    const persons = useSelector(state=>state.peopleReducer.persons);
    const rentHomes = useSelector(state=>state.peopleReducer.rentHomes);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
      setTimeout(()=>{setLoading(false)},1000);   
      })

    return (
        
        <div>
            <div className="containers">
            <Navbar />
                <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        {loading ? (
                                    <Blocks
                                    visible={true}
                                    height="80"
                                    width="100%"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                  />
                                    ) :(        <div className="right">
                            <div className="firstly">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page"><i class="fa-solid fa-house"></i> Tableau de bord</li>
                                </ol>
                            </nav>

                                    <div>
                                    <iframe src="https://charts.mongodb.com/charts-dash--mayedo-fcask/embed/dashboards?id=6536fd1a-20dc-47f9-8caa-16eb09af50e6&theme=light&autoRefresh=true&maxDataAge=300&showTitleAndDesc=false&scalingWidth=fixed&scalingHeight=fixed"></iframe>
                                    </div>
                                    {/* <div className="container--block--document">
                                         <div className="cards">
                                            <div className="cards--header">
                                                <h4>Locataires</h4>
                                                <i className="fa-solid fa-user"></i>
                                            </div>
                                            <h5>{persons.length}</h5>
                                        </div>
                                        <div className="cards">
                                            <div className="cards--header">
                                                <h4>Locations</h4>
                                                <i class="fa-solid fa-envelope"></i>
                                            </div>
                                            <h5>{rentHomes.length}</h5>
                                        </div>
                                        <div className="cards">
                                            <div className="cards--header">
                                                <h4>Loyers</h4>
                                                <i class="fa-solid fa-address-book"></i>
                                            </div>
                                            <h5>100</h5>
                                        </div>
                                    </div> */}
                                    <div>
                                    </div>

                                    {/* <div className="container__rent__info">
                                    <div class="alert alert-danger" role="alert">
                                        3 loyer(s) impayé(s)
                                      </div>
                                    </div> */}
                                 
                                </div>
                            </div>
)}
                            
                           

                            
                        </div>
         </div>
    </div>
    )
}