import './App.css';
import {Routes,Route} from 'react-router-dom';
import 'animate.css';
import Accueil from './Pages/Accueil';
import Details from './Pages/Details';
import Update from './Pages/Update';
import Profil from './Pages/Profil';
import Home from './Pages/Home';
import Paiement from './Pages/Paiement';
import Receipt from './Pages/Receipt';
import PaiementDetail from './Pages/PaiementDetail';
import NewStudent from './Pages/NewStudent';
import Student from './Pages/Student';
import Bulletins from './Pages/Bulletins';
import Notes from './Pages/Notes';
import DetailStudent from './Pages/DetailStudent';


export default function App() {
  return (
    <>
    <Routes>
    {/* <Route path='/' element={<Home/>}/> */}
    <Route path='/' element={<Accueil/>}/>
    <Route path='/details/:id' element={<Details/>}/>
    <Route path='/new' element={<NewStudent/>}/>
    <Route path='/mon-profil' element={<Profil/>}/>
    <Route path='/student' element={<Student/>}/>
    <Route path='/update/:id' element={<Update/>}/>
    <Route path='/paiement/:id' element={<Paiement/>}/>
    <Route path='/detailStudent/:id' element={<DetailStudent/>}/>
    <Route path='/receipt/:id' element={<Receipt/>}/>
    <Route path='/paiementDetail/:id' element={<PaiementDetail/>}/>
    <Route path='/bulletins/:id' element={<Bulletins/>}/>
    <Route path='/notes' element={<Notes/>}/>
  </Routes>

    </>
  );
}

