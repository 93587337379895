import { useState } from "react";
import { useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getPerson, getRent } from "../Redux/actions";
import { useReactToPrint } from "react-to-print";
import Pdf from '../Document/compo_java.pdf';
// import "" from "./compo_java";


export default function Bulletins({note,setNote}){

        var id = useParams().id;
    const person = useSelector(state=>state.peopleReducer.person);
    const rental = useSelector(state=>state.peopleReducer.rental);
    const [loading, setLoading] = useState(true);
    const[pay,setPay]=useState("");
    const dispatch = useDispatch(false);

    const Payment = () =>{
        setPay(true)
    }

    const falseNote = ()=>{
        setNote(false)
    }

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/persons/${id}`)
        .then((res)=>res.json())
        .then((person)=>{dispatch(getPerson(person))})
        .catch(e => { console.log(e)})
        setTimeout(()=>{setLoading(false)},1000);   
        }, [person])

        useEffect(() => {
            fetch(`https://mayedo.onrender.com/rents/${id}`)
            .then((res)=>res.json())
            .then((rental)=>{dispatch(getRent(rental))})
            .catch(e => { console.log(e)})
            setTimeout(()=>{setLoading(false)},1000);   
            }, [rental])

      const componentRef =useRef();
      const handlePrint =useReactToPrint({
        content:()=> componentRef.current,
        documentTitle:person.name +' '+person.prenom,
        // onAfterPrint:()=> alert('Print successful')
      })


    return (
        <div>
            <div className={note ? "container__bulletin__modal open__bulletin__modal" : "container__bulletin__modal"}>
            <div className="container__bulletins__header">
                              <h3 className="gle"><i className="fa-solid fa-note-sticky"></i> Bulletins de notes</h3>
                              <i className="fa-solid fa-xmark" onClick={falseNote}></i>
                              </div>
        <div className="containers">
                        <div className='container__detail__user'>
            <div className="main__part__detail__people" >
                             <div className="main__part__detail__header">
                                <div key={id} className="part__detail__person__container">
                                <div className="detail--person--profil">
                                        <div className="detail--person--profil--ml">
                                        <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt=''/>
                                        </div>
                                    </div>
                                <div className="detail--person">
                                    <p>Nom : {person.name}</p>
                                    <p>Prénom : {person.prenom}</p>
                                    <p>Né(e) : {person.birth}</p>
                                    <p>Tel :{person.tel}</p>
                                    <p>Sexe : {person.sexe}</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>N° Tel. :</p>
                                    <p>Email: {person.email}</p>
                                    <p>Profession: {person.profession}</p>
                                    <p>Classe actuelle: {person.email}</p>
                                    <p>Date d'inscription: {person.date_entrance}</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>Nom & Prenom du père:</p>
                                    <p>N° père: </p>
                                    <p>Nom & Prenom de la mère :</p>
                                    <p>N° mère : </p>
                                    </div>
                                  
                                </div>

                            </div>
                        </div> 
                    </div>   
                    <div className="container">
                                <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Année Scolaire</th>
                                    <th scope="col">Etablissement fréquenté</th>
                                    <th scope="col">Classe</th>
                                    <th scope="col">Moyenne Annuelle</th>
                                    <th scope="col">Statut</th>
                                    <th scope="col"><i className="fa-solid fa-print"></i> Imprimer</th>
                                    </tr>
                                </thead>
                                <tbody  className="table-group-divider">
                                     <tr>
                                    <td>2020-2021</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 1 SEI</td>
                                    <td>15,75</td>
                                    <td>Privé</td>
                                    <td><a href = 'compo_java.pdf' target = "_blank"><button className="button__pdf">PDF</button></a></td>
                                    </tr>
                                    <tr>
                                    <td>2021-2022</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 2 SEI</td>
                                    <td>12,75</td>
                                    <td>Privé</td>
                                    <td><button className="button__pdf">PDF</button></td>
                                    </tr>
                                    <tr>
                                    <td>2022-2023</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>Licence 3 INFO</td>
                                    <td>14,75</td>
                                    <td>Privé</td>
                                    <td><button className="button__pdf">PDF</button></td>
                                    </tr>
                                    <tr>
                                    <td>2023-2024</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>MASTER 1 SEI</td>
                                    <td>13,75</td>
                                    <td>Privé</td>
                                    <td><button className="button__pdf">PDF</button></td>
                                    </tr>
                                    <tr>
                                    <td>2024-2025</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>MASTER 2 SEI</td>
                                    <td>16,75</td>
                                    <td>Privé</td>
                                    <td><button className="button__pdf">PDF</button></td>
                                    </tr>
                                </tbody>
                                </table>

                               </div>  
                                                 
                           </div>
                           </div>              
   </div>
    )
}