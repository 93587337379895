import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { getPeople } from "../Redux/actions";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { getPerson} from "../Redux/actions";


export default function Update(){
    var id = useParams().id;
    const person = useSelector(state=>state.peopleReducer.person);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(`http://127.0.0.1:4000/persons/${id}`)
        .then((res)=>res.json())
        .then((person)=>{dispatch(getPerson(person))})
        .catch(e => { console.log(e)})
        }, [person])
    

    const navigate = useNavigate();


    const [name,setName]=useState("");
    const [prenom,setPre]=useState("");
    const [birth,setDate]=useState("");
    const [lieu,setLieu]=useState("");
    const [nationality,setNationality]=useState("");
    const [situation,setSituation]=useState("");
    const [sexe,setSexe]=useState("");
    const [tel,setTel]=useState("");
    const [profession,setProfession]=useState("");
    const [address,setAddresse]=useState("");
    const [email,setEmail]=useState("");
    const [piece,setPiece]=useState("");
    const [current_status,setCurrentStatus]=useState("");
    const [city,setCity]=useState("");
    // const [rent,setRents]=useState("");
    const [guarantee,setGuarantee]=useState("");
    const [date_entrance,setDateEntrance]=useState("");
    const [house,setHouse]=useState("");
    const [date_emission,setEmission]=useState("");
    const [date_expiration,setExpiration]=useState("")

    useEffect(()=>{
        setName(person.name);
        setPre(person.prenom)
        setDate(person.birth);
        setLieu(person.lieu);
        setNationality(person.nationality);
        setSituation(person.situation);
        setSexe(person.sexe);
        setTel(person.tel);
        setProfession(person.profession);
        setEmail(person.email);
        setPiece(person.piece);
        setAddresse(person.address);
        setCurrentStatus(person.current_status);
        setCity(person.city);
        // setRents(person.rent);
        setGuarantee(person.guarantee);
        setDateEntrance(person.date_entrance);
        setEmission(person.date_emission);
        setExpiration(person.date_expiration)
    }, [])

    const handleName = (event) =>{
        setName(event.target.value)
    }
    const handlePre = (event) =>{
        setPre(event.target.value)
    }
    const handleLieu = (event) =>{
        setLieu(event.target.value)
    }
    const handleDate = (event) =>{
        setDate(event.target.value)
    }
    const handleNationality = (event) =>{
        setNationality(event.target.value)
    }
    const handleSituation = (event) =>{
        setSituation(event.target.value)
    }
    const handleSexe = (event) =>{
        setSexe(event.target.value)
    }
    const handleTel = (event) =>{
        setTel(event.target.value)
    }
    const handleProfession = (event) =>{
        setProfession(event.target.value)
    }
    const handleAddresse = (event) =>{
        setAddresse(event.target.value)
    }
    const handleEmail = (event) =>{
        setEmail(event.target.value)
    }
    const handleCni = (event) =>{
        setPiece(event.target.value)
    }
    const handleCurrent = (event) =>{
        setCurrentStatus(event.target.value)
    }
    const handleHouse = (event) =>{
        setHouse(event.target.value)
    }
    const handleGuarantee = (event) =>{
        setGuarantee(event.target.value)
    }
    // const handleRents = (event) =>{
    //     setRents(event.target.value)
    // }
    const handleEntrance = (event) =>{
        setDateEntrance(event.target.value)
    }
    const handleExpiration = (event) =>{
        setExpiration(event.target.value)
    }
    const handleEmission = (event) =>{
        setEmission(event.target.value)
    }

    const notify = (e) => {
        toast('Profil mis à jour avec succès!!', {
          position: "top-right",
          autoClose: 3001,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      };

    const handleUpdate = (e) =>{
        const dataUpdate = {
          name,prenom,birth,lieu,nationality,address,current_status,date_emission,date_expiration,date_entrance,
          situation,tel,profession,email,piece,city,person_id:id,home_id:house
        }
        fetch(`https://mayedo.onrender.com/person/${id}`,{
              method:"PUT",
              headers :{'Content-Type':"application/json"},
              body: JSON.stringify(dataUpdate)
          }).then((res)=>res.json())
            .catch(err => {console.log(err)})
            navigate('/users');
            notify("") 
      };
  

    return (
        <div>

        <div className="containers">
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   <Navbar/>
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item " aria-current="page"><Link to='/update'>Mise à jour</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Profils</li>
                                </ol>
                            </nav>
                       <div className='container__detail'>
            <div className="main__part__detail__people" >
                {/* <div className='btn__close__block'>
                    <Link to='/users'>
                        <button className='btn__back'><i class="fa-solid fa-arrow-left"></i></button>
                        </Link>
                </div> */}
                <div className="title__detail">
                        <h1>Mise à jour du profil</h1>
                </div>
                <div className="main__part__detail__header">
                                <div key={id} className="part__detail__person__container">
                                <div className="detail--person--profil">
                                        <div className="detail--person--profil--ml">
                                        <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt=''/>
                                        </div>
                                    </div>
                                <div className="detail--person">
                                    <p>Nom : {person.name}</p>
                                    <p>Prénom : {person.prenom}</p>
                                    <p>Né(e) : {person.birth}</p>
                                    <p>Nationnalité : {person.nationality}</p> 
                                    <p>Tel :{person.tel}</p>
                                    <p>Sexe : {person.sexe}</p>
                                    <p>N° Tel. :</p>
                                    <Link to={`/update/${person._id}`}><i class="fa-solid fa-pen-to-square"></i></Link>
                                    </div>
                                    <div className="detail--person">
                                    <p>Email: XXX XXX XXX</p>
                                    <p>Profession: XXX XXX XXX</p>
                                    <p>Domicile : XXX XXX XXX</p>
                                    <p>Date d'inscription: XXX XXX XXX</p>
                                    <p>Nº CNI ou PAS : XXX XXX XXX </p>
                                    <p>Date d'expiration:  XX XXX XXX</p>
                                    <p>Affecté : Non</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>Classe actuelle:  XX XXX XXX</p>
                                    <p>Nom & Prenom du père:  XX XXX XXX </p>
                                    <p>N° père: +225 00000000 </p>
                                    <p>Nom & Prenom de la mère : XX XXX XXX</p>
                                    <p>N° mère : +225 00000000</p>
                                    <p>Matricule : </p>
                                    </div>
                                  
                                </div>

                            </div>
                            
                        </div> 
                    </div>  
                    <form className="row g-3">
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Noms</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleName} value={name} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Prénom(s)</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handlePre} value={prenom}/>
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date de naissance</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleDate} value={birth}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Lieu</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleLieu} value={lieu}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Nationalité</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleNationality} value={nationality} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Tel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleTel} value={tel}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">Profession</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleProfession} value={profession}/>
                                    </div>
                                    <div className="col-md-2">
                                    <label htmlFor="validationDefault02" className="form-label">Sexe</label>
                                            <select class="form-select" aria-label="Default select example" required onChange={handleSexe} value={sexe} >
                                                <option selected>Sélectionner</option>
                                                <option value="M">M</option>
                                                <option value="F">F</option>
                                                </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Email</label>
                                        <input type="email" className="form-control" id="validationDefault02" required onChange={handleEmail} value={email}/>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Domicile</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleAddresse} value={address}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'entrée</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleEntrance} value={date_entrance}/>
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="validationDefault02" className="form-label">N° CNI ou PASSEPORT</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleCni} value={piece}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'emission</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleEmission} value={date_emission} />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="validationDefault02" className="form-label">Date d'expiration</label>
                                        <input type="date" className="form-control" id="validationDefault02" required onChange={handleExpiration} value={date_expiration} />
                                    </div>
                                  
                                    <div className="btn--block--update">
                                            <Link to="/detailUser"><button className="btn__">Annuler</button></Link>
                                            <button className="btn__" onClick={handleUpdate}>Enregistrer</button>
                                    </div>
                                    </form>                        
                           </div>
                       </div>
                   </div>
  
    </div>
   </div>
    )
}