import { useEffect, useState,useRef } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import {getRent,getPerson } from "../Redux/actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";




export default function Receipt(){
    const person = useSelector(state=>state.peopleReducer.person);
    const rental = useSelector(state=>state.peopleReducer.rental);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    var id = useParams().id;

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/persons/${id}`)
        .then((res)=>res.json())
        .then((person)=>{dispatch(getPerson(person))})
        .catch(e => { console.log(e)})
        setTimeout(()=>{setLoading(false)},1000);   
        }, [person])

        useEffect(() => {
            fetch(`https://mayedo.onrender.com/rents/${id}`)
            .then((res)=>res.json())
            .then((rental)=>{dispatch(getRent(rental))})
            .catch(e => { console.log(e)})
            setTimeout(()=>{setLoading(false)},1000);   
            }, [rental])

            console.log(rental)

    const componentRef =useRef();
    const handlePrint =useReactToPrint({
      content:()=> componentRef.current,
      documentTitle:person.name +' '+person.prenom,
      // onAfterPrint:()=> alert('Print successful')
    })
  
    return (
        <div>
             <div className="containers">
             <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   <Navbar/>
                   <div className="firstly">
                   <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item " aria-current="page"><Link to='/users'>Liste Locataires</Link></li>
                                    <li class="breadcrumb-item" aria-current="page">Details du profils</li>
                                    <li class="breadcrumb-item active" aria-current="page">Ajout du paiement</li>
                                </ol>
                            </nav>
                            <div className="container__print">
                            <button className="btn__print" onClick={handlePrint}>Imprimer le recu</button>
                            </div>

                            <div ref={componentRef} style={{width:'100%',height:window.innerHeight}}>
                                    <div className="receipt__container">
                                         <div className="receipt__nav">
                                            <h1>Récu paiement N° {rental._id}</h1>
                                            </div>
                                        <div className="receipt__header">
                                            <div className="receipt__logo">
                                            <img src={`${process.env.PUBLIC_URL}/27.png`} alt=''/>
                                            </div>
                                            <div className="enterprise__details">
                                                    <p>Email:sci@mayedo.ci</p>
                                                    <p>Tel : 07 77 88 00 82</p>
                                                    <p>Cocody 8ème Tranche </p>
                                            </div>
                                        </div>
                                            <h1 className="receipt__title"><u>Quittance de Loyer</u></h1>
                                        <div className="description__persons">
                                            <div className="description__persons__owner">
                                                <h5 className="person__info"><u>Le proprietaire</u></h5>
                                                <p>SCI MAYEDO</p>
                                                <p>+225 07 77 88 00 82</p>
                                                <p>sci@mayedo.ci</p>
                                                <p>Cocody Angré 8ème Tranche</p>
                                            </div>
                                            <div className="description__persons__roomer">
                                                <div className="description__persons__roomer__content">
                                                <h5 className="person__info"><u>Le bailleur</u></h5>
                                                <p><strong>M. {person.name} {person.prenom}</strong></p>
                                                <p>{person.tel}</p>
                                                <p>{person.email}</p>
                                                <p>{person.address}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="receipt__description">
                                           <div className="receipt__description__left">
                                           <p className="person__name">Recu de <strong>M. {person.name} {person.prenom}</strong></p>
                                           {person.home_id?.map((home,_id) => <div>
                                            <p>La somme de <strong>{home.rent} FCFA</strong></p> 

                                            <p key={_id}>Le <strong>{rental.date_of_payment}</strong></p>

                                            <p>Pour le loyer de la <strong>{home.categorie} </strong> sise à :<br/><strong>Cocody 8ème Tranche</strong></p>
                                         
                                                     <p key={rental._id}>En paiement du terme du mois de <strong>{rental.month}</strong></p>
                                           </div>
                                            )}
                                           </div>
                                           <div className="receipt__description__right">
                                                <p>Fait à Abidjan le <strong>{rental.date_of_payment}</strong></p>
                                                <p><strong>Cachet & Signature du Proprietaire</strong></p>
                                           </div>
                                        </div>
                                        {/* <div className="receipt__footer">

                                        </div> */}
                                        
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                           

                            
                        </div>
       
         </div>
        </div>
    )
}