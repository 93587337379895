import { useState } from "react";
import { useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { getPerson, getRent } from "../Redux/actions";
import { useReactToPrint } from "react-to-print";
import { TailSpin } from "react-loader-spinner";
import PaiementModal from "./PaiementModal";


export default function PaiementDetail(){

        var id = useParams().id;
    const person = useSelector(state=>state.peopleReducer.person);
    const rental = useSelector(state=>state.peopleReducer.rental);
    const [loading, setLoading] = useState(true);
    const[pay,setPay]=useState("");
    const dispatch = useDispatch();

    const Payment = () =>{
        setPay(true)
    }

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/persons/${id}`)
        .then((res)=>res.json())
        .then((person)=>{dispatch(getPerson(person))})
        .catch(e => { console.log(e)})
        setTimeout(()=>{setLoading(false)},1000);   
        }, [person])

        useEffect(() => {
            fetch(`https://mayedo.onrender.com/rents/${id}`)
            .then((res)=>res.json())
            .then((rental)=>{dispatch(getRent(rental))})
            .catch(e => { console.log(e)})
            setTimeout(()=>{setLoading(false)},1000);   
            }, [rental])

      const componentRef =useRef();
      const handlePrint =useReactToPrint({
        content:()=> componentRef.current,
        documentTitle:person.name +' '+person.prenom,
        // onAfterPrint:()=> alert('Print successful')
      })

    return (
        <div>

        <div className="containers">
        <Navbar/>
            <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   {loading ? (
                                 <TailSpin
                                 height="80"
                                 width="100%"
                                 display='flex'
                                 align-items='center'
                                 color="#4fa94d"
                                 ariaLabel="tail-spin-loading"
                                 radius="1"
                                 wrapperStyle={{}}
                                 wrapperClass=""
                                 visible={true}
                               />
                                    ) :(     <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li className="breadcrumb-item " aria-current="page"><Link to='/users'>Liste etudiants</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Details du profil</li>
                                    <li className="breadcrumb-item active" aria-current="page">Details des paiements</li>
                                </ol>
                            </nav>
                       <div className='container__detail__paiements'>
            <div className="main__part__detail__people" >
                <div className='nav--pdf'>
                    {/* <Link to='/users'><button className='btn__back'><i class="fa-solid fa-arrow-left"></i></button></Link> */}
                    <button className="btn__pay" onClick={Payment}> <i className="fa-solid fa-money-check"></i> Ajouter un paiement</button>
                    <button className="button__pdf__xl" onClick={handlePrint}><i class="fa-solid fa-print"></i> Imprimer</button>
                </div>
                <PaiementModal pay={pay} setPay={setPay}/>
                <div ref={componentRef} style={{width:'100%',height:window.innerHeight}}>
                <div className="main__part__detail__header">
                                <div key={id} className="part__detail__person__container">
                                <div className="detail--person--profil">
                                        <div className="detail--person--profil--ml">
                                        <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt=''/>
                                        </div>
                                    </div>
                                    <div className="detail--person">
                                    <p>Nom : {person.name}</p>
                                    <p>Prénom : {person.prenom}</p>
                                    <p>Né(e) : {person.birth}</p>
                                    <p>Nationnalité : {person.nationality}</p> 
                                    <p>Tel :{person.tel}</p>
                                    <p>Sexe : {person.sexe}</p>
                                    <p>N° Tel. :</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>Email: XXX XXX XXX</p>
                                    <p>Profession: XXX XXX XXX</p>
                                    <p>Domicile : XXX XXX XXX</p>
                                    <p>Date d'inscription: XXX XXX XXX</p>
                                    <p>Nº CNI ou PAS : XXX XXX XXX </p>
                                    <p>Date d'expiration:  XX XXX XXX</p>
                                    <p>Affecté : Non</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>Classe actuelle:  XX XXX XXX</p>
                                    <p>Nom & Prenom du père:  XX XXX XXX </p>
                                    <p>N° père: +225 00000000 </p>
                                    <p>Nom & Prenom de la mère : XX XXX XXX</p>
                                    <p>N° mère : +225 00000000</p>
                                    <p>Matricule : XXXXXXXXX FFF </p>
                                    </div>
                                  
                                </div>

                            </div>
                       
                    
                        <div className="paiement__block">
                                        <h3><i>Details des paiements</i></h3>
                                        </div>
                                <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Année Scolaire</th>
                                    <th scope="col">Date de paiement</th>
                                    <th scope="col">Etablissement fréquenté</th>
                                    <th scope="col">Classe</th>
                                    <th scope="col">Montant du versement</th>
                                    <th scope="col">Statut</th>
                                    </tr>
                                </thead>
                                <tbody  className="table-group-divider">
                                     <tr>
                                    <td>2023-2024</td>
                                    <td>12/05/2023</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 2 SEI</td>
                                    <td>150 000 FCFA</td>
                                    <td>Privé</td>  
                                    </tr>

                                    <tr>
                                    <td>2023-2024</td>
                                    <td>12/05/2023</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 2 SEI</td>
                                    <td>150 000 FCFA</td>
                                    <td>Privé</td>  
                                    </tr>
                                    <tr>
                                    <td>2023-2024</td>
                                    <td>12/05/2023</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 2 SEI</td>
                                    <td>150 000 FCFA</td>
                                    <td>Privé</td>  
                                    </tr>
                                    
                                </tbody>
                                </table>
                                </div>
                               </div>                        
                           </div>
                           </div> 
                                    )}
                       </div>
                   </div>
  
    </div>
   </div>
    )
}