import { Link ,useParams} from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux"
import { useState,useEffect  } from "react";
import { getAllPerson } from "../Redux/actions";
import { Blocks } from "react-loader-spinner";


export default function Student (){

    const persons = useSelector(state=>state.peopleReducer.persons);
    const keys = ["name","prenom"];
    const dispatch = useDispatch();
    const [search,setSearch]=useState("")
    const [loading, setLoading] = useState(true);
    const [newPeople, setNewPeople] = useState(false);

    const DisplayNew = () =>{
        setNewPeople(true)
    }

    const handleSearch = (event) =>{
        setSearch(event.target.value)
    }

    useEffect(() => {
        fetch('https://mayedo.onrender.com/persons')
        .then((res)=>res.json())
        .then((persons)=>{dispatch(getAllPerson(persons))})
        .catch(e => { console.log(e)})
        setTimeout(()=>{setLoading(false)},1500);   
        }, [])

    return (
        <div>
        <div className="containers">
        <Navbar />
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Liste etudiants</li>
                                </ol>
                            </nav>
                            <div className="firstly__header">
                               <h1><i className="fa-solid fa-users"></i> Etudiant </h1>
                               </div>
                               <div className="container__filter">
                                <div className="filter--container--content">
                                     <div className="form-floating col-md-7">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={search} onChange={handleSearch}/>
                                    <label for="floatingInput">Rechercher</label>
                                    </div>

                                    </div>
                                    <div className="filter__block">
                                        <div className="col-md-5">
                                            <select className="form-select" aria-label="Default select example">
                                                <option selected>Sélectionner la classe</option>
                                                <option value="1">F2</option>
                                                <option value="2">F3</option>
                                                <option value="3">G2</option>
                                                </select>
                                            </div>
                                    </div>
                                </div>

                                {loading ? ( <Blocks visible={true}height="80" width="100%" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper"/>
                                    ) :(   <table class="table">
                                  <thead>
                                    <tr>
                                    <th className="coler">Nom</th>
                                    <th className="coler">Prénom(s)</th>
                                    <th className="coler">Contacts</th>
                                    <th className="coler">Classe</th>
                                    <th className="coler">N° matricule</th>
                                    <th className="coler">Details</th>
                                    </tr>
                                </thead>
                                <tbody class="table-group-divider">
                             {persons && persons.filter((person,_id) => keys.some((key) => {
                                    if (search === ""){
                                        return person;
                                    }
                                    else if (person[key].toLocaleLowerCase().includes(search.toLocaleLowerCase())){
                                        return person;
                                    }
                                    return 0})).map((person) => <tr key={person._id}>
                                    <td className="coles">{person.name}</td>
                                    <td className="coles">{person.prenom}</td>
                                    <td className="coles">{person.tel}</td>
                                    <td className="coles">{person.address}</td>
                                    {person.home_id?.map((home,_id) => <td className="coles">{home.categorie}</td> )} 
                                    {person.home_id?.map((home,_id) => <td className="coles">{home.rent} FCFA</td>)} 
                                    <td className="coles"> <Link to={`/detailStudent/${person._id}`}><button className="details__btn">Details</button></Link></td>
                                    </tr>
                                            )}
                                </tbody>
                                </table>)}
                            <div>
                                
                            </div>
                           </div>
                       </div> 
                   </div>
    </div>
        </div>
    )
}