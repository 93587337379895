import { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { addPayment, addPerson } from "../Redux/actions";
import { Link, useNavigate, useParams } from "react-router-dom";




export default function Paiement(){

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const person = useSelector(state=>state.peopleReducer.person);
    let id = useParams().id;
    // let person =persons.find(person => person.id == id);

    const [name,setName]=useState("");
    const [prenom,setPre]=useState("");
    const [rent,setMontant]=useState("");
    const [date_of_payment,setDate]=useState("");
    const [month,setMonth]=useState("");
    const [address,setAddresse]=useState("");
    const [status,setStatus]=useState("");
    const [house,setHouse]=useState("");


    useEffect(()=>{
        setName(person.name);
        setPre(person.prenom);
        setMontant(person.rent);
        setAddresse(person.address)
    }, [person])

    const handleName = (event) =>{
        setName(event.target.value)
    }
    const handlePre = (event) =>{
        setPre(event.target.value)
    }
    const handleMontant = (event) =>{
        setMontant(event.target.value)
    }
    const handleDate = (event) =>{
        setDate(event.target.value)
    }
    const handleMonth = (event) =>{
        setMonth(event.target.value)
    }
    const handleStatus = (event) =>{
        setStatus(event.target.value)
    }
    const handleAddresse = (event) =>{
        setAddresse(event.target.value)
    }

    const handleHouse = (event) =>{
        setHouse(event.target.value)
    }
    const notify = (e) => {
        toast('Profil mis à jour avec succès!!', {
          position: "top-right",
          autoClose: 3001,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      };


    const handleAdd = (e) =>{
        const dataForm = {
          date_of_payment,month,status,person_id:id
        }
        fetch('https://mayedo.onrender.com/rents',{
              method:"POST",
              headers :{'Content-Type':"application/json"},
              body: JSON.stringify(dataForm)
          }).then((res)=>res.json())
          .then((dataPayment)=> dispatch(addPayment(dataPayment))) 
            .catch(err => {console.log(err)})
            navigate('/users');
            notify("")
            console.log(dataForm)
      };
  
    return (
        <div>
             <div className="containers">
             <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   <Navbar/>
                   <div className="firstly">
                   <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item " aria-current="page"><Link to='/users'>Liste Locataires</Link></li>
                                    <li class="breadcrumb-item" aria-current="page">Details du profils</li>
                                    <li class="breadcrumb-item active" aria-current="page">Ajout du paiement</li>
                                </ol>
                            </nav>
                            
                               <div className="container__payment">
                                <div className="container__payment__content">
                               <h3>Ajouter un paiement</h3>
                                <form className="row g-3">
                                    <div className="col-md-6">
                                        <label htmlFor="validationDefault02" className="form-label">Date de paiement</label>
                                        <input type="date" className="form-control" id="validationDefault02" required  onChange={handleDate} value={date_of_payment|| ""} defaultValue="Search..."/>
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validationDefault02" className="form-label">Mois</label>
                                        <select class="form-select" aria-label="Default select example" required onChange={handleMonth} value={month}>
                                                <option selected>Sélectionner</option>
                                                <option value="Janvier">Janvier</option>
                                                <option value="Février">Février</option>
                                                <option value="Mars">Mars</option>
                                                <option value="Avril">Avril</option>
                                                <option value="Mai">Mai</option>
                                                <option value="Juin">Juin</option>
                                                <option value="Juillet">Juillet</option>
                                                <option value="Août">Août</option>
                                                <option value="Septembre">Septembre</option>
                                                <option value="Octobre">Octobre</option>
                                                <option value="Novembre">Novembre</option>
                                                <option value="Décembre">Décembre</option>
                                                </select>
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="validationDefault02" className="form-label">Noms</label>
                                        <input type="text" className="form-control" id="validationDefault02" required  onChange={handleName} value={name|| ""} defaultValue="Search..."/>
                                    </div>
                                    <div className="col-md-8">
                                        <label htmlFor="validationDefault02" className="form-label">Prénom(s)</label>
                                        <input type="text" className="form-control" id="validationDefault02" required  onChange={handlePre} value={prenom|| ""} defaultValue="Search..."/>
                                    </div>
                                    
                                    <div className="col-md-4">
                                        <label htmlFor="validationDefault02" className="form-label">Loyer mensuel</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleMontant} value={rent} />
                                    </div>
                                    <div className="col-md-7">
                                        <label htmlFor="validationDefault02" className="form-label">Domicile</label>
                                        <input type="text" className="form-control" id="validationDefault02" required onChange={handleAddresse} value={address} />
                                    </div>
                                    <div className="col-md-5">
                                    <label htmlFor="validationDefault02" className="form-label">Statut</label>
                                    <select class="form-select" aria-label="Default select example"  onChange={handleStatus} value={status}>
                                        <option selected>Sélectionner</option>
                                        {/* <option value="Impayés">Impayés</option> */}
                                        <option value="Payé">Payé</option>
                                        </select>
                                    </div>
                                    <div className="btn--block--update">
                                            <button className="btn__">Annuler</button>
                                            <button className="btn__" onClick={handleAdd}>Enregistrer</button>
                                    </div>
                                    </form>
                                    
                                </div>
                               </div>
                                </div>
                            </div>
                            
                           

                            
                        </div>
       
         </div>
        </div>
    )
}