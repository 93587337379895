import { useState } from "react";
import { useEffect,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { getPerson, getRent } from "../Redux/actions";
import { useReactToPrint } from "react-to-print";
// import { TailSpin } from "react-loader-spinner";
import { Blocks } from "react-loader-spinner";
import PaiementModal from "./PaiementModal";
import Bulletins from "./Bulletins";


export default function DetailStudent(){

    var id = useParams().id;
    const person = useSelector(state=>state.peopleReducer.person);
    const rental = useSelector(state=>state.peopleReducer.rental);
    const [loading, setLoading] = useState(true);
    const[pay,setPay]=useState("");
    const dispatch = useDispatch(false);
    const [note,setNote]=useState(false);

    const Payment = () =>{
        setPay(true)
    }

    const displayNote = ()=>{
        setNote(true)
    }

    useEffect(() => {
        fetch(`https://mayedo.onrender.com/persons/${id}`)
        .then((res)=>res.json())
        .then((person)=>{dispatch(getPerson(person))})
        .catch(e => { console.log(e)})
        setTimeout(()=>{setLoading(false)},1000);   
        }, [person])

        useEffect(() => {
            fetch(`https://mayedo.onrender.com/rents/${id}`)
            .then((res)=>res.json())
            .then((rental)=>{dispatch(getRent(rental))})
            .catch(e => { console.log(e)})
            setTimeout(()=>{setLoading(false)},1000);   
            }, [rental])



    const notify = (e) => {
        toast('Profil mis à jour avec succès!!', {
          position: "top-right",
          autoClose: 3001,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      };

  
      const componentRef =useRef();
      const handlePrint =useReactToPrint({
        content:()=> componentRef.current,
        documentTitle:person.name +' '+person.prenom,
        // onAfterPrint:()=> alert('Print successful')
      })

    return (
        <div>

        <div className="containers">
        <Navbar/>
            <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                   {loading ? ( <Blocks height="80" width="100%" display='flex' align-items='center'color="#4fa94d" ariaLabel="tail-spin-loading" radius="1" wrapperStyle={{}} wrapperClass="" visible={true}/>
                                    ) :(     <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item " aria-current="page"><Link to='/student'>Liste etudiant</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Details du profils</li>
                                </ol>
                            </nav>
                        <PaiementModal pay={pay} setPay={setPay}/>
                        <Bulletins note={note} setNote={setNote}/>
                        <div className='container__detail__user'>
            <div className="main__part__detail__people" >
                <div className='btn__close__block'>
                    <Link to='/student'><button className='btn__back'><i className="fa-solid fa-arrow-left"></i></button></Link>
                </div>

                <div className="detail__block">
                                        <h2><i>Profil</i></h2>
                                        <div className="detail__bloc__right">
                                        <Link to={`/paiementDetail/${person._id}`}><button className="btn__all">Voir les paiements <i className="fa-solid fa-arrow-right"></i></button></Link>
                                        <button className="btn__add" onClick={displayNote}> <i className="fa-solid fa-note-sticky"></i> Bulletins de notes</button>
                                       <button className="btn__pay" onClick={Payment}> <i className="fa-solid fa-money-check"></i> Ajouter un paiement</button>
                                        <button className="btn__pdf" onClick={handlePrint}><i className="fa-solid fa-print"></i> Imprimer PDF</button>
                                        </div>
                                    </div>
                             <div className="main__part__detail__header">
                                <div key={id} className="part__detail__person__container">
                                <div className="detail--person--profil">
                                        <div className="detail--person--profil--ml">
                                        <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt=''/>
                                        </div>
                                    </div>
                                <div className="detail--person">
                                    <p>Nom : {person.name}</p>
                                    <p>Prénom : {person.prenom}</p>
                                    <p>Né(e) : {person.birth}</p>
                                    <p>Nationnalité : {person.nationality}</p> 
                                    <p>Tel :{person.tel}</p>
                                    <p>Sexe : {person.sexe}</p>
                                    <p>N° Tel. :</p>
                                    </div>
                                    <div className="detail--person">  
                                    <p>Profession: XXX XXX XXX</p>
                                    <p>Domicile : XXX XXX XXX</p>
                                    <p>Date d'inscription: XXX XXX XXX</p>
                                    <p>Nº CNI ou PAS : XXX XXX XXX </p>
                                    <p>Date d'expiration:  XX XXX XXX</p>
                                    <p>Affecté : Non</p>
                                    <p>Classe actuelle:  XX XXX XXX</p>
                                    </div>
                                    <div className="detail--person">
                                    <p>Email: XXX XXX XXX</p>
                                    <p>Nom & Prenom du père:  XX XXX XXX </p>
                                    <p>N° père: +225 00000000 </p>
                                    <p>Nom & Prenom de la mère : XX XXX XXX</p>
                                    <p>N° mère : +225 00000000</p>
                                    <p>Matricule : </p>
                                    <Link to={`/update/${person._id}`}><i class="fa-solid fa-pen-to-square"></i></Link>
                                    </div>
                                  
                                </div>

                            </div>
                        </div> 
                    </div>   
                    <div className="container">
                    <div ref={componentRef} style={{width:'100%',height:window.innerHeight}}>
                    <div className="detail__block">
                                        <h2><i>Cursus Scolaire</i></h2>
                                    </div>
                                    <table className="table">
                                <thead>
                                    <tr>
                                    <th scope="col">Année Scolaire</th>
                                    <th scope="col">Etablissement fréquenté</th>
                                    <th scope="col">Classe</th>
                                    <th scope="col">Statut</th>
                                    </tr>
                                </thead>
                                <tbody  className="table-group-divider">
                                     <tr>
                                    <td>2020-2021</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 1 SEI</td>
                                    <td>Privé</td>
                                    </tr>
                                    <tr>
                                    <td>2021-2022</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>BTS 2 SEI</td>
                                    <td>Privé</td>
                                    </tr>
                                    <tr>
                                    <td>2022-2023</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>Licence 3 INFO</td>
                                    <td>Privé</td>
                                    </tr>
                                    <tr>
                                    <td>2023-2024</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>MASTER 1 SEI</td>
                                    <td>Privé</td>
                                    </tr>
                                    <tr>
                                    <td>2024-2025</td>
                                    <td>ITES II PLATEAUX</td>
                                    <td>MASTER 2 SEI</td>
                                    <td>Privé</td>
                                    </tr>
                                </tbody>
                                </table>

                               </div>  
                               </div>                        
                           </div>
                                    )}
                       </div>
                   </div>
                   <ToastContainer position="top-right" autoClose={10000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light"/>
  
    </div>
   </div>
    )
}