

import { Link } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Navbar from "./Navbar";




export default function Profil(){
    return (
        <div>
        <div className="containers">
        <Navbar />
        <div className="dashboard">
                        <div className="left">        
                           <Sidebar/>
                            
                        </div>
                        <div className="right">
                       <div className="firstly">
                       <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/Accueil'>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Paramètre du compte</li>
                                </ol>
                            </nav>
                                <h1><i class="fa-solid fa-user"></i>  Mon profil</h1>
                                <div className="profil__container">
                                    <div className="profil__first__part">
                                        <div className="profil__first__part__content">
                                        <img src='27.PNG' alt="" />
                                        </div>
                                    </div>
                                    <div className="profil__second__part">
                                        <p>Paramètre du compte</p>
                                    {/* <form class="row g-3 needs-validation" novalidate>
                                        <div class="col-md-5">
                                            <label for="validationCustom01" class="form-label">Nom</label>
                                            <input type="text" class="form-control" id="validationCustom01" required />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="validationCustom02" class="form-label">Prenoms</label>
                                            <input type="text" class="form-control" id="validationCustom02"  required />
                                        </div>
                                        <div class="col-md-6">
                                            <label for="validationCustom02" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="validationCustom02"  required />
                                        </div>

                                        <div class="col-md-5">
                                            <label for="validationCustom03" class="form-label">Addresse</label>
                                            <input type="text" class="form-control" id="validationCustom03" required/>
                                        </div>
                                        <div class="col-md-5">
                                            <label for="validationCustom03" class="form-label">Contacts</label>
                                            <input type="text" class="form-control" id="validationCustom03" required/>
                                        </div>
                                        <div class="col-12">
                                        <button class="btn__cancel" type="submit">Annuler</button>
                                            <button class="btn__form" type="submit">Enregistrer</button>
                                        </div>
                                        </form> */}
                                        <p>Adresse : Cocody Angré 7eme Tranche / Abidjan – Plateau – 01 BP 2076 Abidjan 01</p>
                                        <p>Tel : +225 07 77 88 00 82</p>
                                        <p> E-mail : sci@mayedo.ci</p>
                                        <p>site web : www.mayedo.ci</p>
                                    </div>
                                </div>
                            <div>
                            </div>
                           </div>
                       </div> 
                   </div>
    </div>
   </div>
    )
}