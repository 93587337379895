import { Link ,useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword,signOut} from "../firebaseConfig"
import {auth} from "../firebaseConfig"
import { useState } from "react";



export default function Navbar (){
  const [toogleHelp,setToogle]=useState(false)
  const [sidebar,setSidebar]=useState(false)


  const sidebarMenu = () =>{
    setSidebar(true)
  }

  const sidebarToogle = () =>{
    setToogle(true)
  }
  
  const closeNav = () =>{
    setSidebar(false)
  }

  const Help = () =>{
    setToogle(true)
  }
  const display = ()=>{
    setToogle(false)
  }

    const navigate = useNavigate();
  
        
    const logout = (e) => {
        e.preventDefault();
          signOut(auth)
        .then(() => {
          console.log(auth)
          navigate("/") 
        })
        .catch((error) => {
          console.log(error)
        });
    }
  
    return(
        <div>
            <header>
           <div className="navbar--left">
                {/* <div className="navbagr--menu">
                <i className="fa-solid fa-bars" onClick={sidebarToogle}></i>
                </div> */}
                      <div className="navbar--left--box">
                    <Link to="/Accueil"> <img src={`${process.env.PUBLIC_URL}/7818779.jpg`} alt=''/></Link>
                </div>
           </div>
           <div className="navbar--center">
                {/* <form class="d-flex" role="search">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                <button class="btn btn-outline-success" type="submit">Search</button>
            </form> */}
                      
           </div>
           <div className="navbar--right">
                    {/* <button type="button" class="btn btn-primary">
                        Notifications <span class="badge text-bg-secondary">4</span>
                        </button> */}
                         <button className="btn__help" onClick={Help}> <i class="fa-solid fa-circle-question"></i> Aide </button>
                         {/* <button className="btn__log" onClick={logout}> <i class="fa-solid fa-right-from-bracket"></i> Deconnexion</button> */}
           </div>
            </header>
            <div className="navbar__display">
              <div className="navbar__display__left">
                    <p onClick={sidebarMenu}><i class="fa-solid fa-bars"></i> Menu</p>
              </div>
              <div className="navbar__display__center">
                      <div className="navbar__display__center__logo">
                      <Link to="/Accueil"> <img src={`${process.env.PUBLIC_URL}/27.PNG`} alt=''/></Link>
                      </div>
              </div>
              <div className="navbar__display__right">
                <button className="btn__help" onClick={sidebarToogle}> <i class="fa-solid fa-circle-question"></i> Aide </button>
              </div>

            </div>
            <div className={toogleHelp ? "container__help open__help__part" : "container__help"}>
                          <div className="container__help__header">
                          <h2 className="help__title"><i>#Guide d'utilisation</i></h2>
                          <div className="btn--close--part">
                                    <i className="fa-solid fa-xmark" onClick={display}></i>
                                </div>
                          </div>
                                    <div className="container__help__content">
                                    <div className="session__connexion">
                                  <h4 className="session__connexion__title">1.Session Connexion</h4>
                                    <p>Saisissez votre compte d'utilisation email {'>'} mot de passe </p>
                                    <div className="session__connexion__images">
                                    <img src={`${process.env.PUBLIC_URL}/dash.png`} alt=''/>
                                    </div>
                                 </div>
                                    <div className="session__connexion">
                                    <h4 className="session__connexion__title">2.Accedez au tableau de bord</h4>
                                    <div className="session__connexion__images">
                                    <img src={`${process.env.PUBLIC_URL}/dash4.png`} alt=''/>
                                    </div>
                                    </div>
                                      <div className="session__connexion">
                                      <h4 className="session__connexion__title">3.Ajouter un nouveau locataire</h4>
                                        {/* <h3>4.Remplissez le formulaire</h3> */}
                                        <p>Saisissez les donneées du nouveau locataire </p>
                                        <div className="session__connexion__images">
                                        <img src={`${process.env.PUBLIC_URL}/ajout.png`} alt=''/>
                                        </div>
                                      </div>
                                       <div className="session__connexion">
                                       <h4 className="session__connexion__title">5.Accédez a la liste des locataires</h4>
                                        <div className="session__connexion__images">
                                        <img src={`${process.env.PUBLIC_URL}/list.png`} alt=''/>
                                        </div>
                                       </div>
                                        <div className="session__connexion">
                                        <h4 className="session__connexion__title">6.Accédez aux details d'un locataire</h4>
                                        <div className="session__connexion__images">
                                        <img src={`${process.env.PUBLIC_URL}/dash3.png`} alt=''/>
                                        </div>
                                        </div>
                                        <div className="session__connexion">
                                        <h4 className="session__connexion__title">7.Effectuez un paiement de loyer</h4>
                                        <div className="session__connexion__images">
                                            <img src={`${process.env.PUBLIC_URL}/pay.png`} alt=''/>
                                        </div>
                                        </div>
                                        <div className="session__connexion">
                                        <h4 className="session__connexion__title">8.Imprimez le reçu</h4>
                                        <div className="session__connexion__images">
                                            <img src={`${process.env.PUBLIC_URL}/print.png`} alt=''/>
                                        </div>
                                        </div>
                                        
                                    </div>
                                  
                                </div>

                                <div className={sidebar ? "navbar__menu open__navbar__menu" : "navbar__menu"}>
                                  <div className="container__btn__close__menu">
                                  <i class="fa-solid fa-xmark" onClick={closeNav}></i>
                                  </div>
                                <div className="sidebar">
                                    {/* <p className="profil--user"><i className="fa-solid fa-user"></i> ID: 77880082</p> */}
                                <div className="sidebar--item">
                                    <Link to='/Accueil' className="link__sidebar"><p><i class="fa-brands fa-dashcube"></i> Tableau de bord</p></Link>
                                </div>
                                {/* <div className="sidebar--item">
                                    <Link to='/order' className="link__sidebar"><p><i class="fa-solid fa-money-check"></i> Loyers</p></Link>
                                </div> */}
                                <div className="sidebar--item">
                                    <Link to='/users' className="link__sidebar"><p><i class="fa-solid fa-users"></i> Locataires</p></Link>
                                </div>
                                <div className="sidebar--item">
                                    <Link to='/renthome' className="link__sidebar"><p><i class="fa-solid fa-house"></i> Locations</p></Link>
                                </div>
                                {/* <div className="sidebar--item">
                                    <Link to='/users' className="link__sidebar"><p><i class="fa-solid fa-gauge-high"></i> Mes performances</p></Link>
                                </div> */}
                                {/* <div className="sidebar--item">
                                    <Link to='/notifications' className="link__sidebar"> <p><i class="fa-regular fa-bell"></i> Mes messages</p></Link>
                                </div> */}
                                {/* <div className="sidebar--item">
                                    <Link to='/notifications' className="link__sidebar"> <p><i class="fa-regular fa-bell"></i> Notifications</p></Link>
                                </div> */}
                                <div className="sidebar--item">
                                    <Link to='/mon-profil' className="link__sidebar"> <p> <i class="fa-solid fa-user"></i> Mon Profil</p></Link>
                                </div>
                                {/* <div className="sidebar--item">
                                    <Link to='/helps' className="link__sidebar"> <p><i class="fa-solid fa-circle-question"></i> Aide</p></Link>
                                </div> */}

                               
                            </div>
                            <div className="container__info__assisst">
                            <p><i class="fa-solid fa-phone"></i> Service assistance</p>
                                <p>+225 07 77 88 00 82</p>
                                <p>doumbia77fode@gmail.com</p>
                            </div>
                            <div>
                            <button className="btn__log"> <i class="fa-solid fa-right-from-bracket"></i> Deconnexion</button>
                            </div>
                                 
                                  
                                </div>

              
        </div>
    )
}